"use client";
import { useEffect } from "react";
import Slideup from "./Slideup";
import SectionWrapper from "./SectionWrapper";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { scrolling } from "../utils/utils";

function About() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".aboutContainer",
        start: "top 10%",
        end: "bottom bottom",
        scrub: 1,
        pin: ".aboutImage",
      },
    });
  }, []);
  return (
    <div className="sm:px-[4vw] px-[3vw] pb-32 pt-2 mt-[calc(10vw)] font-digideck2">
      <div className="sm:px-[8vw] px-[4vw] flex md:flex-row flex-col md:justify-between gap-y-10 gap-x-10 aboutContainer">
        <div className="md:w-[50%] w-full flex flex-col">
          <div className="text-4xl flex flex-col gap-y-2 text-[#FF8C00] ">
            <Slideup custom={0}>
              <span className="font-bold">A Brief Story About the Company</span>
            </Slideup>{" "}
          </div>
          <div className="text-gray-600 text-sm flex flex-col gap-y-4 mt-8 text-justify font-semibold">
            <Slideup>
              <span>
                At{" "}
                <span className="font-bold font-sans">DigiDeck Consulting</span>
                , we specialise in managing creative digital campaigns within
                the corporate and political landscape, uniquely integrating
                offline and online engagement. As the only firm with a dedicated
                team on the ground, we collect real-time, actionable data to
                give our campaigns a distinctive edge and an extensive reach.
              </span>
            </Slideup>
            <Slideup>
              <span>
                As pioneers in engagement-driven digital media strategies, we
                develop tailored content that resonates with diverse voter
                groups. Our campaigns are designed to shape voting behaviour and
                build strong, favourable support bases. With a team of hundreds
                of skilled professionals across various digital media and
                intelligence roles, we lead the industry in excellence, backed
                by a solid track record.
              </span>
            </Slideup>
            <Slideup>
              <span>
                We serve as a platform for young professionals keen to work
                closely with elected leaders and decision-makers, giving them an
                active role in governance. United by a shared purpose, we strive
                to drive meaningful societal change by highlighting issues that
                matter most to the people.
              </span>
            </Slideup>
            <Slideup>
              <span>
                Our founders have been instrumental in shaping some of India’s
                most significant political initiatives, including ABM (Nation
                with NaMo), Varahe Analytics Pvt. Ltd, Inclusive Minds, I-PAC,
                and DesignBoxed. Through these ventures, they have led major
                campaigns and programmes across various states.
              </span>
            </Slideup>
            <Slideup>
              <span>
                Recognising that Indian elections are rooted in grassroots
                connections, we combine modern digital strategies with
                traditional campaigning methods to create people-centred
                campaigns. Every day, our team develops impactful strategies for
                some of the most prominent leaders in Indian politics, ensuring
                an innovative yet traditional approach in every campaign.
              </span>
            </Slideup>
            <Slideup>
              <span>
                Would you like a consultant to hear your needs? We’d be happy to
                help. Please fill out the{" "}
                <span
                  className="text-[#FFA301] font-semibold a cursor-pointer"
                  onClick={() => scrolling("contact")}
                >
                  form below
                </span>
                , and we’ll get in touch with you shortly.
              </span>
            </Slideup>
          </div>
        </div>
        <div className="flex w-full md:hidden">
          <img
            style={{ aspectRatio: 441 / 551 }}
            src={"/aboutus.png"}
            width={100}
            height={100}
            className="w-full h-fit"
            alt="home"
          />
        </div>
        <div className="md:flex w-[40%] aboutImage hidden">
          <img
            src={"/aboutus.png"}
            width={100}
            height={100}
            className="w-full h-fit"
            alt="home"
          />
        </div>
      </div>
    </div>
  );
}

export default SectionWrapper(About, "about");
